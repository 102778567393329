import React from "react"

const Footer = () => {
  return (
    <footer className="flex justify-between pb-4">
      <p className="text-sm font-semibold text-gray-600">
        ©搜索 {new Date().getFullYear()}
      </p>
    </footer>
  )
}

export default Footer
