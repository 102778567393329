import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Card from "~/components/styled/card"

const CompanyList = ({ companies, gridCols }) => {
  return (
    <div className={`grid ${gridCols} gap-6`}>
      {companies.map(company => {
        return (
          <Card key={company.id}>
            <Link to={`/companies/${company.slug}`} key={company.id}>
              <div className="px-4 py-6">
                <p>{company.title}</p>
              </div>
            </Link>
          </Card>
        )
      })}
    </div>
  )
}

CompanyList.propTypes = {
  companies: PropTypes.array,
  gridCols: PropTypes.string,
}

CompanyList.defaultProps = {
  gridCols: "grid-cols-1 md:grid-cols-3",
}

export default CompanyList
